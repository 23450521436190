<template>
  <!-- 银联 -->

  <div class="home">
    <div class="recordBtn" @click="toRecord">领取记录</div>
    <div class="card texture">
      <h2>{{ pageData.title }}</h2>
      <p>{{ pageData.subTitle }}</p>

      <img
        v-if="!countUserReceiveData[pageData.receiveName]"
        class="btnImg"
        :src="pageData.btnSrc"
        @click="receiveCard"
      />
      <img v-else class="btnImg" :src="pageData.disabledBtnSrc" />
      <img
        v-if="pageData.isNew"
        class="homenew"
        src="~@/assets/img/unionAct/homenew.png"
      />
    </div>

    <!-- <div class="card">
      <h2>classpass新人体验官20点数包</h2>
      <p>银联白金卡、银联钻石卡持卡人尊享</p>
      <img
        v-if="!countUserReceiveData.onceReceiveHasJoin"
        class="btnImg"
        src="~@/assets/img/unionAct/homebtn1.png"
        @click="() => receiveCard(1)"
      />
      <img v-else class="btnImg" src="~@/assets/img/unionAct/homebtn11.png" />
      <img class="homenew" src="~@/assets/img/unionAct/homenew.png" />
    </div>
    <div class="card">
      <h2>classpass新人体验官20点数包</h2>
      <p>银联白金卡、银联钻石卡持卡人尊享</p>
      <img
        v-if="!countUserReceiveData.monthReceiveHasJoin"
        class="btnImg"
        src="~@/assets/img/unionAct/homebtn2.png"
        @click="() => receiveCard(1)"
      />
      <img v-else class="btnImg" src="~@/assets/img/unionAct/homebtn22.png" />
    </div> -->
    <div class="card">
      <div class="cardItem">
        <div class="itemLabel">服务有效期:</div>
        <div class="itemTxt">即日起至2025年12月31日</div>
      </div>
      <div class="cardItem">
        <div class="itemLabel itemLabel1">服务对象:</div>
        <div class="itemTxt">
          {{ pageData.object }}
        </div>
      </div>
      <div class="cardItem">
        <div class="itemLabel itemLabel2">服务简介:</div>
        <div class="itemTxt" v-html="pageData.service"></div>
      </div>
      <div class="cardItem">
        <div class="itemLabel itemLabel2">领取规则:</div>
        <div class="itemTxt" v-html="pageData.rule"></div>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="popWrapper">
        <img class="icon" :src="msgData.icon" />
        <div class="mainTxt">{{ msgData.mainTitle }}</div>
        <div class="mainSubTxt">
          {{ msgData.subTitle }}
        </div>
        <div class="popBtn" @click="show = false">确定</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { countUserReceive, receiveCard } from '@/api/unionAct'
export default {
  data() {
    return {
      countUserReceiveData: {
        onceReceiveHasJoin: true, // 单次领取-是否参加
        monthReceiveHasJoin: true, // 当月领取-是否参加
      },
      msgList: [
        {
          icon: require('@/assets/img/unionAct/homemsg1.png'),
          mainTitle: '领取成功',
          subTitle: '稍后在“领取记录”内查看'
        },
        {
          icon: require('@/assets/img/unionAct/homemsg2.png'),
          mainTitle: '活动太火爆啦！',
          subTitle: '请稍后再试'
        },
        {
          icon: require('@/assets/img/unionAct/homemsg3.png'),
          mainTitle: '您暂不符合领取规则',
          subTitle: '请详见活动规则'
        }
      ],
      show: false,
      msgData: {
        icon: require('@/assets/img/unionAct/homemsg3.png'),
        mainTitle: 'error',
        subTitle: '请详见活动规则'
      },
      pageData: {

      }
    }
  },
  mounted() {
    this.initPageData()
    if (!this.isItLegal()) return
    this.countUserReceive()
  },
  methods: {
    // 不同路由加载对应数据
    initPageData() {
      let routeName = this.$route.name
      const mapData = {
        // 普卡-新人
        'unionActOrdinaryNewPeople': {
          title: 'classpass新人体验官20点数包',
          subTitle: '银联普卡，银联金卡持卡人尊享',
          isNew: true,
          cycleType: 1, // 活动周期类型 1单次 2每月一次
          rightCode: 'CARD_GOLD', // 领取的时候传给接口的卡类型
          receiveName: 'onceReceiveHasJoin', // 已领取字段键值：单次领取-是否参加onceReceiveHasJoin；当月领取-是否参加monthReceiveHasJoin
          btnSrc: require('@/assets/img/unionAct/homebtn1.png'),
          disabledBtnSrc: require('@/assets/img/unionAct/homebtn11.png'),
          object: '银联普卡，金卡信用卡和银联普卡，金卡借记卡人持卡人',
          service: '银联普卡，银联金卡持卡人可尊享classpass会员权益，包括：classpass新人体验官20点数包、折扣券等优惠。',
          rule: '权益服务期间，每位用户仅可领取1次classpass新人体验官权益',
        },
        // 普卡-复购
        'unionActOrdinaryRepurchase': {
          title: 'classpass复购折扣券',
          subTitle: '银联普卡，银联金卡持卡人尊享',
          isNew: false,
          cycleType: 2, // 活动周期类型 1单次 2每月一次
          rightCode: 'CARD_GOLD', // 领取的时候传给接口的卡类型
          receiveName: 'monthReceiveHasJoin', // 已领取字段键值：单次领取-是否参加onceReceiveHasJoin；当月领取-是否参加monthReceiveHasJoin
          btnSrc: require('@/assets/img/unionAct/homebtn2.png'),
          disabledBtnSrc: require('@/assets/img/unionAct/homebtn22.png'),
          object: '银联普卡，金卡信用卡和银联普卡，金卡借记卡人持卡人',
          service: '银联普卡，银联金卡持卡人可尊享classpass会员权益，包括：classpass新人体验官20点数包、折扣券等优惠。',
          rule: '权益服务期间，每位用户每月仅可领取1次classpass复购折扣月月领权益。',
        },
        // 钻石卡-新人
        'unionActMasonryNewPeople': {
          title: 'classpass新人体验官20点数包',
          subTitle: '银联白金卡、银联钻石卡持卡人尊享',
          isNew: true,
          cycleType: 1, // 活动周期类型 1单次 2每月一次
          rightCode: 'CARD_MASONRY', // 领取的时候传给接口的卡类型
          receiveName: 'onceReceiveHasJoin', // 已领取字段键值：单次领取-是否参加onceReceiveHasJoin；当月领取-是否参加monthReceiveHasJoin
          btnSrc: require('@/assets/img/unionAct/homebtn1.png'),
          disabledBtnSrc: require('@/assets/img/unionAct/homebtn11.png'),
          object: '银联钻石、白金信用卡和银联钻石、白金借记卡持卡人',
          service: '银联白金卡、银联钻石卡持卡人可尊享classpass会员权益，包括：classpass新人体验官20点数包、折扣券等优惠。',
          rule: '权益服务期间，每位用户仅可领取1次classpass新人体验官权益',
        },
        // 钻石卡-复购
        'unionActMasonryRepurchase': {
          title: 'classpass复购折扣券',
          subTitle: '银联白金卡、银联钻石卡持卡人尊享',
          isNew: false,
          cycleType: 2, // 活动周期类型 1单次 2每月一次
          rightCode: 'CARD_MASONRY', // 领取的时候传给接口的卡类型
          receiveName: 'monthReceiveHasJoin', // 已领取字段键值：单次领取-是否参加onceReceiveHasJoin；当月领取-是否参加monthReceiveHasJoin
          btnSrc: require('@/assets/img/unionAct/homebtn2.png'),
          disabledBtnSrc: require('@/assets/img/unionAct/homebtn22.png'),
          object: '银联钻石、白金信用卡和银联钻石、白金借记卡持卡人',
          service: '银联白金卡、银联钻石卡持卡人可尊享classpass会员权益，包括：classpass新人体验官20点数包、折扣券等优惠。',
          rule: '权益服务期间，每位用户每月仅可领取1次classpass复购折扣月月领权益。',
        },
      }
      this.pageData = mapData[routeName]
      // console.log(this.pageData);
    },
    // 是否合法访问
    isItLegal() {
      let { token } = this.$route.query
      if (!token) {
        this.$toast.fail('请携带参数')
        return false
      } else {
        sessionStorage.setItem('unionActToken', token)
        return true
      }
    },
    // 统计用户领取记录
    countUserReceive() {
      countUserReceive({}).then((res) => {
        this.countUserReceiveData = res.data
        // this.$toast.success(res.msg)
      })
    },
    // 领取权益
    // 活动周期类型 1单次 2每月一次
    receiveCard() {
      receiveCard({
        cycleType: this.pageData.cycleType,
        rightCode: this.pageData.rightCode
      }).then((res) => {
        // console.log('then', res);
        if (res.code === 0) {
          // 领取成功
          this.msgData = this.msgList[0]
          this.countUserReceive()
        } else if (res.message === '活动太火爆，请稍后再试') {
          // 活动太火爆（图标需要特殊处理）
          this.msgData = this.msgList[1]
        } else {
          // 其他错误
          this.msgData = {
            icon: require('@/assets/img/unionAct/homemsg3.png'),
            mainTitle: res.message,
            subTitle: '请详见活动规则'
          }
        }
        this.show = true
      })
    },

    toRecord() {
      this.$router.push({
        name: 'unionActRecord'
      })
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background: url('~@/assets/img/unionAct/homebg.jpg') top center no-repeat;
  background-size: 100%, 100%;
  padding: 358px 0 2px;
  box-sizing: border-box;
  background-color: #010303;
  position: relative;
  overflow: hidden;
  .recordBtn {
    position: absolute;
    top: 130px;
    right: -1px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 15px 0px 0px 15px;
    border: 1px solid #ffffff;
    font-size: 12px;
    color: #ffffff;
    padding: 6px 12px;
  }
  .card {
    background: #17181e;
    border-radius: 10px;
    border: 1px solid #2a2d3c;
    width: 90%;
    margin: 0 auto 20px;
    padding: 40px 15px 15px;
    text-align: center;
    position: relative;
    h2 {
      font-size: 20px;
      background: linear-gradient(0deg, #ecbd81 0%, #ffeed8 42.48046875%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      color: #866a54;
      padding: 5px 0 28px;
    }
    .btnImg {
      width: 260px;
      margin: auto;
    }
    .homenew {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 80px;
    }
    .cardItem {
      display: flex;
      color: #ffecd6;
      text-align: left;
      font-size: 12px;
      margin-bottom: 18px;
      .itemLabel {
        color: #c89d7a;
        padding: 0 10px 0 20px;
        background: url('~@/assets/img/unionAct/homeicon1.png') 0px 2px
          no-repeat;
        background-size: 12px, 12px;
        &.itemLabel1 {
          background: url('~@/assets/img/unionAct/homeicon2.png') 0px 2px
            no-repeat;
          background-size: 12px, 12px;
        }
        &.itemLabel2 {
          background: url('~@/assets/img/unionAct/homeicon3.png') 0px 2px
            no-repeat;
          background-size: 12px, 12px;
        }
      }
      .itemTxt {
        flex: 1;
        line-height: 1.5;
      }
    }
  }
  .texture {
    background: url('~@/assets/img/unionAct/cardbg.png') no-repeat;
    background-size: 100% 100%;
    border: none;
    margin: 0 auto 15px;
    padding: 50px 15px 15px;
    width: 92%;
    overflow: hidden;
    border-radius: 10px;
  }
}
.popWrapper {
  width: 80%;
  // background: linear-gradient(0deg, #f8e6d2, #eecaa2);
  // border-radius: 10px;
  background: url('~@/assets/img/unionAct/pop-bg.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  text-align: center;
  padding: 30px 0;
  font-size: 14px;
  color: #a26b48;
  .icon {
    width: 33px;
    display: block;
    margin: auto;
  }
  .mainTxt {
    font-size: 22px;
    color: #4b2708;
    padding: 13px 0;
  }
  .popBtn {
    width: 165px;
    height: 43px;
    line-height: 43px;
    background: #000000;
    border-radius: 21px;
    font-size: 19px;
    color: #f5dec5;
    margin: 30px auto 0;
  }
}
</style>
